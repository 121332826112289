<template>
  <div :class="$style.controls">
    <button type="button" @click="$emit('go-prev')">
      <Icon name="arrow" :class="$style.leftArrow" />
    </button>
    <span>{{ curPage }}/{{ lastPage }}</span>
    <button type="button" @click="$emit('go-next')">
      <Icon name="arrow" :class="$style.rightArrow" />
    </button>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon'

export default {
  components: {
    Icon
  },
  props: {
    curPage: {
      type: Number,
      default: 1
    },
    lastPage: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors';

.controls {
  padding: 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin: 0 1.5rem;
  }
  button {
    width: 2rem;
    height: 2rem;
    border: none;
    outline: none;
    cursor: pointer;
    background: $bright-gold;
    border-radius: 0.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &:focus,
    &:active {
      background: gray;
    }
    .leftArrow,
    .rightArrow {
      padding: 0 0.4rem 0 0rem;
      width: 100%;
      height: 100%;
    }
    .rightArrow {
      transform: rotate(180deg);
    }
  }
}
</style>
